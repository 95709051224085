/*
 * Note: this is borrowed from the front end
 * and includes commented-out stubs for auth
 * to match the process on the front end.
 */

import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

// import { useAuth } from '@/lib/auth/authContext';

function useApollo() {
  //   const auth = useAuth();

  //   const getAuthHeaders = () => {
  //     if (!auth?.authUser) return null;

  //     return {
  //       authorization: auth.authUser.token ? `Bearer ${auth.authUser.token}` : '',
  //     };
  //   };

  const createApolloClient = () => {
    // const headers = getAuthHeaders();
    const link = new HttpLink({
      uri: process.env.REACT_APP_API_ENDPOINT,
      //   headers,
    });

    return new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  };

  return {
    createApolloClient,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function AuthedApolloProvider({ children }: { children: React.ReactNode }) {
  const apollo = useApollo();

  return <ApolloProvider client={apollo.createApolloClient()}>{children}</ApolloProvider>;
}
